import { useContext, useEffect, useRef, useState } from "react";
import "./Catalog.css";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CATALOG_MAIN_LINK } from "../../assets/utils/constants";
import { buildCategoryPath } from "../../assets/utils/utils";
import { Helmet } from "react-helmet";
import MiniPreloader from "../MiniPreloader/MiniPreloader";
import CategoryCard from "./CategoryCard/CategoryCard";
import Products from "./Products/Products";
import mainApi from "../../assets/api/MainApi";
import { ConfigContext } from "../../assets/contexts/configContext";
import { FiltersContext } from "../../assets/contexts/filtersContext";
import FiltersPopup from "./Products/FiltersPopup/FiltersPopup";

function parse_filters(filters, searchParams) {
  if (!filters || filters.length === 0) return null
  return filters.map((filter) => {
    let valueFromParams = searchParams ? searchParams.get(`filter_${filter._id}`) : null;
    if (valueFromParams) {
      console.log(filter, valueFromParams)

      switch (filter.data_type) {
        case 'range': {
          const [GTE, LTE] = valueFromParams.split('-').map(Number);
          return {
            ...filter,
            value: [GTE, LTE]
          };
        }
        case 'bool': {
          return {
            ...filter,
            value: JSON.parse(valueFromParams)
          };
        }
        case 'string':
        case 'integer': {
          try {
            // Попробуем распарсить как массив
            const parsedValue = JSON.parse(valueFromParams);

            if (Array.isArray(parsedValue)) {
              return {
                ...filter,
                value: parsedValue
              };
            } else {
              // Если это не массив, добавляем как есть
              return {
                ...filter,
                value: valueFromParams
              };
            }
          } catch (error) {
            // Если не получилось распарсить как JSON, просто добавим как строку
            return {
              ...filter,
              value: valueFromParams
            };
          }
        }
        default: {
          return null
        }
      }
    } else {
      //   Default value handling
      switch (filter.data_type) {
        case 'range': {
          const GTE = filter.features.gte;
          const LTE = filter.features.lte;
          return {
            ...filter,
            value: [GTE, LTE]
          };
        }
        case 'bool': {
          return {
            ...filter,
            value: filter.filter_type === 'in_stock' ? false : null
          };
        }
        default: {
          return filter;
        }
      }
    }
  }).filter((item) => Boolean(item));
}

const PRODUCTS_LIMIT = 25

function usePrevious(value) {
  const ref = useRef(null);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function Catalog({ levels }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const prevLevels = usePrevious(levels);
  const config = useContext(ConfigContext)
  const { initialConstants } = config
  const { SHOP_ID_FOR_API, SHOP_NAME, USING_FILTERS } = initialConstants
  const location = useLocation();
  const navigate = useNavigate();
  const [preloaders, setPreloaders] = useState({
    item: true,
    more: false,
    products: true,
    subcategories: true,
  });
  const [popups, setPopups] = useState({
    filters: false,
  })
  const [category, setCategory] = useState(undefined);

  const [filterValues, setFilterValues] = useState(null)

  useEffect(() => {
    // Сравнение предыдущего и текущего значения levels
    console.log(JSON.stringify(prevLevels), JSON.stringify(levels))
    if (JSON.stringify(prevLevels) === JSON.stringify(levels)) {
      return; // Если массив levels не изменился, выходим из useEffect
    }

    console.log({
      levels: {
        levels,
        length: levels.length,
      }
    });
    if (!levels || levels.length <= 0) {
      setCategory({
        _id: null,
        title: 'Каталог',
        path: null,
        categories: [],
      });

      getSubcategories({ _id: null });
      getItems({ _id: null })
      setPreloaders((prevState) => ({
        ...prevState,
        item: false,
      }));
      return
    }
    setPreloaders((prevState) => ({
      ...prevState,
      item: true,
    }));
    mainApi
      .getCategoriesWithTree({
        shop_id: SHOP_ID_FOR_API,
        data: levels,
      })
      .then((res) => {
        console.log(res)
        const data =
          res.data.length > 0 ? res.data[res.data.length - 1] : undefined;
        const filters = USING_FILTERS && data.filters ? data.filters.filter((item) => !item.name.startsWith('_') && !(item.name.toLowerCase().indexOf('артикул') >= 0) && !(item.option_name === 'Артикул')) : null
        console.log(data.filters)
        setCategory({
          _id: data._id,
          title: data.name,
          path: res.data,
          categories: [],
          filters: filters
        });
        setFilterValues(parse_filters(filters, searchParams))
        if (data) {
          if (!data.is_final) getSubcategories({ _id: data._id })
          else {
            setPreloaders(prevValue => ({
              ...prevValue,
              subcategories: false,
            }))
          }
          getItems({ _id: data._id })
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setPreloaders((prevState) => ({
          ...prevState,
          item: false,
        }));
      });
  }, [levels]);

  function getSubcategories({ _id }) {
    setPreloaders((prevState) => ({
      ...prevState,
      subcategories: true,
    }));
    mainApi
      .getCategories({
        limit: 25,
        shop_id: SHOP_ID_FOR_API,
        _id: _id ? _id : null,
      })
      .then((res) => {
        setCategory((prevVal) => ({
          ...prevVal,
          categories: res.data,
        }));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setPreloaders((prevState) => ({
          ...prevState,
          subcategories: false,
        }));
      });
  }

  function getItems({ _id }) {
    const parsedFilters = [];
    searchParams.forEach((value, key) => {
      if (key.startsWith('filter_')) {
        if (!USING_FILTERS) {
          searchParams.delete(key)
          setSearchParams(searchParams, { replace: true })
          return
        }
        console.log({ key, value });
        const filterId = key.replace('filter_', '');

        try {
          // Попробуем распарсить как массив
          const parsedValue = JSON.parse(value);

          if (Array.isArray(parsedValue)) {
            parsedFilters.push({
              filter_id: filterId,
              data: parsedValue,
            });
          } else {
            // Если это не массив, добавляем как есть
            parsedFilters.push({
              filter_id: filterId,
              data: value,
            });
          }
        } catch (error) {
          // Если не получилось распарсить как JSON, просто добавим как строку
          parsedFilters.push({
            filter_id: filterId,
            data: value,
          });
        }
      }
    });

    console.log({ parsedFilters });
    setPreloaders((prevState) => ({
      ...prevState,
      products: true,
    }));
    mainApi
      .getItems({
        shop_id: SHOP_ID_FOR_API,
        category: _id ? _id : null,
        limit: PRODUCTS_LIMIT,
        filters: USING_FILTERS && parsedFilters ? parsedFilters : null,
      })
      .then((res) => {
        console.log(res)
        setCategory((prevVal) => ({
          ...prevVal,
          items: res,
        }));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setPreloaders((prevState) => ({
          ...prevState,
          products: false,
        }));
      });
  }
  console.log(category)

  function getMoreProducts() {
    const last_id = category.items.data[category.items.data.length - 1]._id
    setPreloaders((prevState) => ({
      ...prevState,
      more: true,
    }));
    mainApi
      .getItems({
        shop_id: SHOP_ID_FOR_API,
        category: category && category._id ? category._id : null,
        limit: PRODUCTS_LIMIT,
        last_id: last_id,
      })
      .then((res) => {
        console.log(res)
        setCategory((prevValue) => ({
          ...prevValue,
          items: {
            data: prevValue.items.data.concat(res.data),
            is_more: res.is_more,
          },
        }));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setPreloaders((prevState) => ({
          ...prevState,
          more: false,
        }));
      });
  }


  function handleFiltersReset() {
    console.log('RESET', filterValues)
    setFilterValues(parse_filters(category.filters))
    category.filters.forEach((filter) => {
      searchParams.delete(`filter_${filter._id}`);
    });

    // Update the URL with the cleared search params
    setSearchParams(searchParams, { replace: true });

    getItems({ _id: category._id })
  }

  function handleFiltersApply() {
    const parsedValues = filterValues.filter(item => item.value != null).map((item) => {
      console.log(item, item.value)
      switch (item.data_type) {
        case 'range': {
          if (item.value[0] === item.value[1]) return null
          return ({ filter_id: item._id, data: `${item.value[0]}-${item.value[1]}` });
        }
        case 'integer': {
          return ({ filter_id: item._id, data: JSON.stringify(item.value) });
        }
        case 'string': {
          return ({ filter_id: item._id, data: JSON.stringify(item.value) });
        }
        default: {
          return ({ filter_id: item._id, data: item.value });
        }
      }
    }).filter((item) => Boolean(item))
    // Add each filter to the search params
    parsedValues.forEach(({ filter_id, data }) => {
      try {
        if (Array.isArray(JSON.parse(data)) && JSON.parse(data).length === 0) {
          searchParams.delete(`filter_${filter_id}`);
        }
        else searchParams.set(`filter_${filter_id}`, data);
      }
      catch {
        searchParams.set(`filter_${filter_id}`, data);
      }


    });

    // Update the URL with the new search params
    setSearchParams(searchParams, { replace: true });

    console.log('APPLY', parsedValues);
    getItems({ _id: category._id })
  }

  function handleOpenFilterPopup() {
    setPopups(prevValue => ({
      ...prevValue,
      filters: true,
    }))
  }
  console.log({ category })

  function handlePopupClose({ name }) {
    setPopups(prevValue => ({
      ...prevValue,
      [name]: false,
    }))
  }

  // console.log(category.categories)
  const desiredOrder = [
    'Римские шторы',
    'Комплекты штор',
    'Подушки и покрывала',
    'Ткани для штор'
  ];
  const sortedItems = category && category.categories ? category.categories.sort((a, b) =>
    desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name)
  ) : []
  return (
    <>
      <Helmet>
        <title>{SHOP_NAME} - ...</title>
      </Helmet>
      {preloaders.item ? (
        <div className="catalog__preloader">
          <MiniPreloader />
        </div>
      ) : category ? (
        <div className="catalog">
          <Helmet>
            <title>{SHOP_NAME} - {category.title}</title>
          </Helmet>
          <div className="catalog__content">
            <div className="catalog__crumbs">
              {category.path ? category.path.map((cat, i) => (
                <Link
                  to={`/${CATALOG_MAIN_LINK}/${buildCategoryPath(
                    category.path,
                    i
                  )}`}
                >
                  <p className="catalog__crumb">
                    {cat.name}
                    {i !== category.path.length - 1 ? ` ›` : ""}
                  </p>
                </Link>
              )) : null}
            </div>
            <h2 className="catalog__category-title">{category.title}</h2>
            {!preloaders.subcategories ?
              sortedItems && sortedItems.length > 0 ?
                <div className="catalog__categories-inside">
                  {sortedItems.map((cat, i) => (
                    <CategoryCard
                      levels={levels}
                      category={cat}
                      categories={category.categories}
                      i={i}
                    />
                  ))}
                </div>
                : null
              :
              <div className="catalog__categories-preloader">
                <MiniPreloader />
              </div>
            }

            <FiltersContext.Provider value={{
              filters: category.filters,
              handleFiltersReset,
              handleFiltersApply,
              filterValues,
              setFilterValues,
              handleOpenFilterPopup,
              isLoading: preloaders.products,
            }}>
              <Products
                items={category.items}
                path={category.path}
              />
              <FiltersPopup
                isOpen={popups.filters}
                handleClose={handlePopupClose}
                popupName={'filters'}
              />
            </FiltersContext.Provider>

            {category && category.items && category.items.is_more ?
              <button className={`catalog__more ${preloaders.more ? 'catalog__more_loading' : ''}`} type='button' onClick={() => {
                getMoreProducts()
              }}>
                {preloaders.more ? <MiniPreloader isLinkColor={true} /> : 'Загрузить еще'}
              </button>
              : null}
          </div>
        </div>
      ) : (
        <div className="catalog__not-found">
          {/* <NotFoundBlock
                text={'Кажется, Вы&nbsp;попытались открыть товар, который пока не&nbsp;поступил к&nbsp;нам в&nbsp;продажу'}
                goToMain={true}
            /> */}
        </div>
      )}
    </>
  );
}

export default Catalog;
