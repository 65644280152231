import { useContext } from 'react';
import PortfolioCard from '../PortfolioCard/PortfolioCard';
import { PROJECTS_LIST } from './helpers';
import './PortfolioList.css';
import { ContactContext } from '../../../../../assets/contexts/contactContext';

function PortfolioList() {
  const { setContactPopupOpen: setOpen } = useContext(ContactContext);

  function openPopup() {
    setOpen(true);
  }

  return (
    <ul className="portfolio-list">
      {PROJECTS_LIST.map((item, i) => (
        <li className="portfolio-list__item" key={i}>
          <PortfolioCard item={item} />
        </li>
      ))}

      <li className="portfolio-list__card">
        <p className="portfolio-list__card-logo">VINTASHTORY</p>
        <div className="portfolio-list__title-box">
          <p className="portfolio-list__card-number">12 000</p>
          <p className="portfolio-list__card-title">Довольных клиентов</p>
        </div>
        <button
          className="portfolio-list__card-btn"
          type="button"
          onClick={openPopup}
        >
          Оставить заявку
        </button>
      </li>
    </ul>
  );
}

export default PortfolioList;
