import img from '../../../../../assets/images/custom/zamer.webp';
import './ZamerVariantsTemplate.css';
import proem from '../../../../../assets/images/custom/zamer/2.jpg';
import proemStena from '../../../../../assets/images/custom/zamer/3.jpg';
import proemPotolok from '../../../../../assets/images/custom/zamer/4.jpg';
import stvorka from '../../../../../assets/images/custom/zamer/5.jpg';
import stvorka2 from '../../../../../assets/images/custom/zamer/6.jpg';
import { motion } from 'framer-motion';

const textsList = [
    {
        text: 'в проем окна',
        link: 'proem'
    },
    {
        text: 'на проем окна с креплением к стене',
        link: 'proem-stena'
    },
    {
        text: 'на проем окна с креплением к потолку',
        link: 'proem-potolok'
    },
    {
        text: 'на створку пластикового окна',
        link: 'stvorka'
    }
];

const variants = [
    {
        title: 'Монтаж в проем окна',
        how: `Для установки в проем окна необходимо замерить`,
        list: [
            'Ширину в верхней части проема',
            'Высоту проема – расстояние от «потолка проема» до подоконника'
        ],
        text: `Следует учесть, что в очень редких случаях ширина проема в верхней и нижней его части совпадает, 
        также и высота проема в правой и левой его части может отличаться. 
        Поэтому, чтобы римские шторы вписались в проем окна, необходимо от ширины проема отнять 2 см, а высоту оставить как есть.`,
        img: proem,
        listvariant: [
            'Ширина Римской шторы = Ширина проема – 2 см;',
            'Высота Римской шторы = Высота проема.'
        ]
    },
    {
        title: 'Установка над проемом окна с креплением к стене',
        how: `Для монтажа рулонных штор на стену нужно замерить:
        `,
        list: [
            'Необходимую ширину изделия (как правило, к ширине проема добавляют 10 см, чтобы изделие перекрывало проем);',
            'Необходимую высоту изделия (как правило, римские шторы крепят на расстоянии 10-25 см над проемом окна).'
        ],
        text: `Перед тем, как производить замер, посмотрите, не помешают ли установке римских штор над окном трубы отопления, кондиционер и его коммуникации, ниши или выступы.`,
        img: proemStena,
        listvariant: [
            'Ширина Римской шторы = Ширина проема + 10 см;',
            'Высота Римской шторы = Высота проема + (10-25 см).'
        ]
    },
    {
        title: 'Монтаж на проем окна с креплением к потолку',
        how: `Для монтажа римских штор к потолку нужно замерить:`,
        list: [
            'Необходимую ширину изделия (как правило, к ширине проема добавляют 10 см, чтобы изделие перекрывало проем);',
            'Необходимую высоту изделия.'
        ],
        text: ``,
        img: proemPotolok,
        listvariant: [
            'Ширина Римской шторы = Ширина проема + 10 см;',
            'Высота Римской шторы = Высота от потолка до подоконника.'
        ]
    },
    {
        title: 'Монтаж на створку пластикового окна',
        how: `Для монтажа римских штор на створку нужно замерить:`,
        list: [
            'Необходимую ширину изделия (как правило, это ширина по границам штапиков окна);',
            'Высоту створки целиком.'
        ],
        text: ``,
        img: stvorka,
        img2: stvorka2,
        listvariant: [
            'Ширина римской шторы = Ширина по границам штапиков окна;',
            'Высота римской шторы = Высота створки (рамы) целиком.'
        ]
    },
]

function ZamerVariantsTemplate({ active }) {

    return (
        variants.map((item, i) => {
            return (
                active === i ?
                    <motion.div
                        animate={{ opacity: 1 }}
                        initial={{ opacity: 0 }}
                        transition={{ type: 'easeOut' }}
                        className="zamer-variants">
                        <p id={textsList[i].link} className='zamer-variants__title'>{item.title}</p>
                        <p>Для установки {textsList[i].text} необходимо замерить:</p>
                        <ul className="zamer-info__list">
                            {item.list.map((item, i) => (
                                <li className="zamer-info__item" key={i}>
                                    {/* <a className="zamer-info__item" href={`#${item.link}`} > */}
                                    <span className="zamer-info__number">{i + 1}</span>
                                    <p className="zamer-info__text">
                                        {item}
                                    </p>
                                </li>
                            ))}
                        </ul>
                        <p>{item.text}</p>
                        <img src={item.img} alt='' className='zamer-variants__img'></img>
                        <p>При таком варианте установки необходимо заказать римские шторы следующих размеров:</p>
                        <ul className="zamer-info__list_variants">
                            {item.listvariant.map((item, i) => (
                                <li className="zamer-info__item" key={i}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                        <circle cx="3" cy="3" r="3" transform="rotate(180 3 3)" fill="#c0a274" />
                                    </svg>
                                    <p className="zamer-info__text">
                                        {item}
                                    </p>
                                </li>
                            ))}
                        </ul>
                        {item.img2 ?
                            <img src={item.img2} alt='' className='zamer-variants__img'></img>
                            : null
                        }
                    </motion.div>
                    : null
            )
        })
    );
}

export default ZamerVariantsTemplate;